<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept 
            type="search" 
            label="점검주관부서" 
            name="deptCd" 
            v-model="searchParam.deptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            default="today"
            type="year"
            label="년도"
            name="year"
            v-model="searchParam.year"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 종류 -->
          <c-select
            type="search"
            codeGroupCd="SOP_PRE_WORK_CHECK_CLASS_CD"
            itemText="codeName"
            itemValue="code"
            name="sopPreWorkCheckClassCd"
            label="점검구분"
            v-model="searchParam.sopPreWorkCheckClassCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 진행단계 -->
          <c-select
            type="search"
            stepperGrpCd="PWC_PRE_WORK_CHECK_STEP_CD"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="pwcPreWorkCheckStepCd"
            label="진행단계"
            v-model="searchParam.pwcPreWorkCheckStepCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="card cardcontents">
      <div class="card-body qtable-card-body">
        <q-table
          grid
          class="qtable-card"
          title=""
          :data="grid.data"
          :columns="grid.columns"
          hide-header
          hide-bottom
          :rows-per-page-options="[0]"
          virtual-scroll
        >
          <template v-slot:top-right>
          </template>
          <template v-slot:item="props">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
              <q-card class="mobileTableCardLayer" @click="linkClick(props.row)">
                <q-card-section class="grid-card-etc">
                  <div :class="['text-grid-etc', getColorStatus(props.row.pwcPreWorkCheckStepCd)]">
                    {{ props.row.pwcPreWorkCheckStepName }}
                  </div>
                  <div :class="['text-grid-etc', getColorStatus2(props.row.approvalStatusCd)]" v-if="props.row.approvalStatusCd">
                    {{ props.row.approvalStatusName }}
                  </div>
                </q-card-section>
                <q-card-section>
                  <div class="text-grid-title q-mt-sm q-mb-xs">{{ props.row.checkName }}</div>
                </q-card-section>
                <q-card-section class="grid-card-etc">
                  <div class="text-grid-etc">
                    {{ props.row.yearmonth }} | {{ props.row.deptName }}
                  </div>
                </q-card-section>
              </q-card>
            </div>
          </template>
        </q-table>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="grid.data.length === 0">
          <q-card class="mobileTableCardLayer">
            <q-card-section class="text-center">
              <q-card-section class="q-pt-xs">
                <div class="text-grid-title q-mt-sm q-mb-xs">데이터가 존재하지 않습니다.</div>
              </q-card-section>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import mixinFooterSearch from '@/js/mixin-footer-search-common'
export default {
  name: 'preWorkCheck',
  mixins: [mixinFooterSearch],
  props: {
    scopeProps: {
      type: Object,
      default(){
        return {
          footerSearch: '',
        }
      },
    },
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        deptCd: null,
        year: '',
        pwcPreWorkCheckStepCd: null,
      },
      grid: {
        columns: [
          {
            // 점검명
            name: 'checkName',
            field: 'checkName',
            label: '점검명',
            align: 'left',
            sortable: true,
            type: 'link',
            style: 'width:250px',
          },
          {
            // 진행단계
            name: 'pwcPreWorkCheckStepName',
            field: 'pwcPreWorkCheckStepName',
            label: '진행단계',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            // 결재진행단계
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            label: '결재진행단계',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            // 점검기간
            name: 'period',
            field: 'period',
            label: '점검기간',
            align: 'center',
            sortable: true,
            style: 'width:150px',
          },
          {
            // 관리감독자
            name: 'managerName',
            field: 'managerName',
            label: '관리감독자',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            // 근로자
            name: 'workerNames',
            field: 'workerNames',
            label: '근로자',
            align: 'left',
            sortable: true,
            style: 'width:100px',
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  beforeRouteLeave(to, from, next) { // history.back 일경우 팝업이라면 팝업을 닫자
    if (this.popupOptions.visible) {
      this.closePopup();
      next(false);
    } else {
      next();
    }
  },
  watch: {
    'scopeProps.footerSearch'() {
      this.getList();
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.pwc.check.list.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.popupOptions.title = '일상점검';
      this.popupOptions.param = {
        sopPreWorkCheckId: row.sopPreWorkCheckId,
      };
      this.popupOptions.target = () => import(`${'./preWorkCheckDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    getColorStatus(cd) {
      let cls = '';
      switch(cd) {
        case 'PWC000001': // 작성중
          cls = 'txt-box-grid text-primary-box';
          break;
        case 'PWC000005': // 완료
          cls = 'txt-box-grid text-positive-box';
          break;
      }
      return cls;
    },
    getColorStatus2(cd) {
      let cls = '';
      switch(cd) {
        case 'ASC0000001': // 결재중
          cls = 'txt-box-grid text-gray-box text-blue';
          break;
        case 'ASC9999999': // 결재완료
          cls = 'txt-box-grid text-gray-box text-red';
          break;
      }
      return cls;
    },
  }
};
</script>
